﻿.navbar-dark .navbar-nav {
    .dropdown-menu .nav-link {
        color: inherit;
        padding: 5px 15px 5px 15px;
        white-space: nowrap;
    }

    .level1.nav-link {
        color: #eee;
    }

    .level2.dropdown-menu {
        top: inherit !important;
        left: inherit !important;
        display: flex;
        flex-direction: column;
        min-width: unset;
    }

    .dropdown-menu {
        font-size: inherit;
        border-radius: 0 0 0.45rem .45rem;
        top: 131%;

        .nav-link:hover {
            background-color: #efefef;
        }
    }

    .has-popup .level1.nav-link::after {
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
}
.dropdown-menu-clean-button {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    &:hover, .navbar-nav .nav-item:hover {
        cursor: pointer;
        color: rgba(255,255,255,.75);
    }

    i.fa-user-circle {
        font-size: 1.5rem;
    }
}

.account-menu .dropdown-menu.show {
    display: flex;
    flex-direction: column;
}

.account-menu .dropdown-menu__admin a {
    color: red;
}

.account-menu {
    justify-content: flex-end;
    display: flex;
    flex-grow: 1;
    height: 65px;
}

.account-menu .account-menu__labels {
    display: flex;
    flex-direction: column;

    & .account-menu__orgname {
        display: flex;
        align-items: center;

        & i {
            margin-left: 10px;
        }
    }

}

.navbar-nav .nav-item {
    align-items: center;
    display: flex;
    height:70px;
    padding:0px 20px;
    &.active-tab{
        background-color: #dbe386;
        color: #1d342d;
    }
}

// Buttons
.btn {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
        margin-right: 0.5rem;
        font-size: 1.06rem;
    }

    &.btn-primary {
        background-color: #4885c5;
        border-color: #4885c5;

        &:hover {
            background-color: #4b6a8a;
            border-color: #4b6a8a;
        }
    }

    &.btn-warning {
        background-color: #ffd761;
        border-color: #ffd761;

        :hover {
            background-color: #dab856;
            border-color: #dab856;
        }
    }

    &.btn-danger {
        background-color: #ff7057;
        border-color: #ff7057;
    }

    &.btn-success {
        background-color: #4885c5 !important;
        border-color: #4885c5 !important;
    }
}

.custom-select {
    -webkit-appearance: none;
}

.footer {
    display: flex;
}

.btn-success {
    @include button-variant(#46df29,#46df29, #218838);
    color:#ffffff;
}


.navbar {
    padding:inherit;
}

.navbar .navbar-brand {
    flex-grow: 1;
}

.navbar-dark .navbar-nav .nav-link { //Top navigation
    color: inherit;
}

.navbar-dark .navbar-nav .nav-icon {
    font-size: 1.6em;
    margin-right: 5px;
    margin-bottom: 3px;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255,255,255,.75)
}

.navbar-dark .navbar-nav .dropdown-menu .nav-link {
    color: #000000;
    padding: 5px 15px 5px 15px;
    white-space: nowrap;
}

.collapse.navbar-collapse {
    flex-grow:20;
}

.capq-guide.tooltip .arrow:before {
    border-top-color: #dc3545;
}
.tooltip {
    z-index: 1070;
}

.form-group label{
    font-weight:700;
}

.modal-dialog {
    max-width: 1290px;
}