@import '../../../../../sass/settings.scss';

.user-list {
    .button-row {
        display: flex;
        justify-content: flex-end;
    }

    .datatable i {
        margin-right: $DEFAULT-MARGIN;
    }
}
