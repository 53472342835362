%entity-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: solid 1px #f2f2f2;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

    h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 1rem;
    }

    .button-group {
        display: flex;
        flex-direction: row;
    }
    .button-row {
        display: flex;
        flex-direction: row;

        button, a {
            margin-left: 0.675rem;
        }
    }
}