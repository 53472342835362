@import '../../../../../sass/settings.scss';

.datatable-no-result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $DEFAULT-PADDING;

    font-size: 20px;
    
    .btn {
        display: inline-block;
        margin-top: calc(#{$DEFAULT-MARGIN} * 2);
    }

    .fa-palette, .fa-filter {
        color: $GRAY;
        font-size: 44px;
        margin: 20px 0;
    }
}


.column-ok{
    color: $GREEN
}
.column-danger{
    color: $WARNING
}