.tolerances {
    &__tab-content {
        padding: $DEFAULT-PADDING;
        width: 100%;

        & .modal-content {
            padding-bottom: $DEFAULT-PADDING;
        }
    }

    &__colourset{
        min-width: 150px;
        margin-right: calc(#{$DEFAULT-MARGIN} * 2);
    }

    &__top {
        display: flex;
    }

    &__top-label {
        margin-right: $DEFAULT-MARGIN;
    }

    &__top-action {
        font-weight: bold;
        font-size: 14px;
        margin-right: $DEFAULT-MARGIN;
    }

    &__data-table-td-center {
        text-align: center;
    }

    &__edit-dialog {
        & .modal-dialog {
            max-width: 500px;
        }
    }

    &__edit {
        &__content {
            width: 100%;
            z-index:1;
            background-color: $WHITE;
            padding: calc(#{$DEFAULT-PADDING} * 2);
            border-radius: 10px;
            visibility: hidden;

            .tolerances__edit--active & {
                visibility: visible;
            }
        }
        
        &__form-group{
            display:none;

            &--active{
                display:block;
            }

            &--invalid:after {
                content: 'Field can not be empty';
                color: $WARNING;
            }
        }

        &__editor {
            display: none;

            &--active{
                display:block;
            }
        }

        &__warning {
            display:none;

            &--active {
                display: block;
                padding: $DEFAULT-PADDING;
                border-radius: 10px;
                margin-bottom: $DEFAULT-MARGIN;

                & ul {
                    margin-bottom: 0;
                }
            }
        }
    }
}
