﻿.dataTables_wrapper {
    .datatables-table {
        table {
            thead {
                tr {
                    th {
                        border-bottom: 1px solid $LIGHT-GRAY;
                        padding-top: 0.5em;
                        margin-right: 1em;
                        padding-bottom: 0.5em;
                        white-space: nowrap;
                    }
                }

                .sorting{
                    cursor: pointer;
                    position: relative;
                }
            }

            tfoot {
                tr {
                    th {
                        border-bottom: 1px solid $LIGHT-GRAY;
                        padding-top: 0.5em;
                        padding-bottom: 0.5em;
                    }
                }
            }

            tbody {
                tr {
                    height: 51px;
                    cursor: pointer;

                    &.even {
                        background-color: $LIGHT-GRAY;
                    }

                    &:not(.no-hover):hover {
                        background-color: #e6e6e6;
                    }

                    &.no-hover {
                        cursor: auto !important;
                    }

                    td {
                        padding-top: 0.5em;
                        padding-bottom: 0.5em;
                        border-top: inherit;
                    }
                }
            }

            td, th {
                border: inherit;
                padding-left: 1em;
                vertical-align: inherit;
            }

            td.dataTables_empty {
                font-size: 20px;
                .btn {
                    display: inline-block;
                    margin-top: calc(#{$DEFAULT-MARGIN} * 2);
                }

                .fa-palette, .fa-filter {
                    color: $GRAY;
                    font-size: 44px;
                    margin: 20px 0;
                }
            }
        }

        div.row {
            margin-bottom: 1em;
        }
    }

    .datatables-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 1rem;

        .dataTables_info {
            padding-top: inherit;
        }

        div {
            label {
                display: flex;
                flex-direction: row;
                align-items: center;
                white-space: nowrap;
            }

            select {
                width: auto;
            }

            select, input {
                margin-left: 0.6em;
            }
        }

        .pagination {
            .page-link {
                color: $DARK-GRAY;
            }

            .page-item.active {
                .page-link {
                    color: #eee;
                    background-color: #6c757d;
                    border-color: #dee2e6;
                }
            }

            .page-item.disabled {
                .page-link {
                    color: #bec3c8;
                }
            }

            justify-content: flex-end;
        }
    }
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    bottom: 0.4em;
}

table.dataTable tbody.capq-spinner {
    &:after {
        width: 80px;
        height: 80px;
    }

    & tr {
        opacity: 0.2;
    }
}

[data-access="false"] .dataTables_wrapper .datatables-table table tbody tr {
    cursor: auto;
}

.table-sortimage{
    margin-left: 10px;
}

.pagination{
    cursor: pointer;
}