@import '../../../../sass/settings.scss';

.database-update {
    width: 500px;

    & .progress { //Overrides Bootstrap .progress
        background-color: $CAPQ-LIGHT-LIME;
        outline: 1px solid $CAPQ-LIME;
        margin-bottom: $DEFAULT-MARGIN;
    }
}