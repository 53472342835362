@import '../../../../../sass/settings.scss';

.connection-details{
    .button-row {
        display: flex;
        justify-content: flex-end;
    }

    .content-row{
        display:flex;
        align-items: center;
        margin: $DEFAULT-MARGIN;

        .label{
            margin-right: $DEFAULT-MARGIN;
            font-weight: 700;
            width: 100px;
        }
    }

    .profiles-dropdown{
        width: fit-content;
    }

    .tab-content{
        margin-top: $DEFAULT-MARGIN;

        .content-container{
            margin-bottom: $DEFAULT-MARGIN;
            width: 100%;
        }
    }

    .settings-column{   
        display: flex;
        flex-direction: column;
    }

    .text-ok{
        color: $GREEN;
    }
    .text-stopped{
        color: $WARNING;
    }

    .svg-legend-parent {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;
        justify-content: center;

        .legend-item {
            display: flex;
            flex-direction: row;
            margin-right: $DEFAULT-MARGIN;
            align-items: center;

            .legend-item-blurb {
                width: 14px;
                height: 14px;
                border-radius: 10px 10px;

                &__red{
                    background-color: $CAPQ-RED;
                }
                
                &__yellow{
                    background-color: $CAPQ-LIME;
                }
                &__blue{
                    background-color: #5bc0de;
                }
                &__green{
                    background-color: #28a745;
                }
            }

            .legend-item-text {
                margin-left: $DEFAULT-MARGIN;
                font-size: 0.7rem;
            }
        }
    }
}