/* COLOURS KEEP In SYNC WITH config.js */
$BLACK: #000000;
$WHITE: #ffffff;
$GRAY: #666666;
$GREEN: #28a745;
$CAPQ-RED: #E2004A;
$CAPQ-LIME: #dbe386;
$CAPQ-LIGHT-LIME: #edeede;
$LIGHT-GRAY: #f6f6f6;
$MEDIUM-GRAY: #cccccc;
$DARK-GRAY: #1d342d;
$WARNING: #b53939;
$ERROR: #f3861a;
$INFO:#17a2b8;
$BLUE-LIGHT: #5bc0de;

/* LAYOUT */
$DEFAULT-MARGIN: 10px;
$DEFAULT-PADDING: 10px;

/* MESSAGE */
$MESSAGE-SUCCESS-BACKGROUND-COLOR: $GREEN;
$MESSAGE-SUCCESS-TEXT-COLOR: $WHITE;
$MESSAGE-ERROR-BACKGROUND-COLOR: $CAPQ-RED;
$MESSAGE-ERROR-TEXT-COLOR: $WHITE;
$MESSAGE-WARNING-BACKGROUND-COLOR: $CAPQ-RED;
$MESSAGE-WARNING-TEXT-COLOR: $WHITE;
$MESSAGE-INFO-BACKGROUND-COLOR: $BLUE-LIGHT;
$MESSAGE-INFO-TEXT-COLOR: $WHITE;