@import '../../../../../sass/settings.scss';

.tag-field {
    min-width: 250px;
    display:flex;
    align-items: center;
    position: relative;
    cursor: text;

    &__selected {
        display: flex;
    }

    &__selected-tag {
        display: inline-block;
        box-sizing: border-box;
        margin-right: 4px;
        padding: 4px;
        border: 1px solid $MEDIUM-GRAY;
        border-radius: 2px;
        background: $LIGHT-GRAY;
      
        /* match the font styles */
        font-size: 0.7rem;

        &:after {
            content: '\2715';
            color: #AAA;
            margin-left: 8px;
        }

        &:hover, &:focus {
          border-color: $MEDIUM-GRAY;
        }
    }

    &__search {
        display: inline-block;
      
        /* match tag layout */
        padding: 7px $DEFAULT-PADDING;
        // margin-bottom: 6px;
      
        width: 100%;
    }

    &__search-input {
        /* prevent autoresize overflowing the container */
        min-width: 100%;
      
        /* remove styles and layout from this element */
        margin: 0;
        padding: 0;
        border: 0;
        outline: none;

        &::-ms-clear {
            display: none;
        }
    }

    &__suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1;

        & ul {
            margin: 4px -1px;
            padding: 0;
            list-style: none;
            background: white;
            border: 1px solid $MEDIUM-GRAY;
            border-radius: 2px;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        }

        & li {
            border-bottom: 1px solid $MEDIUM-GRAY;
            padding: 6px $DEFAULT-PADDING;

            &:hover {
                cursor: pointer;
                background: $LIGHT-GRAY;
            }

            &.is-active {
                background: $LIGHT-GRAY;
            }

            &.is-disabled {
                opacity: 0.5;
                cursor: auto;
            }
        }

        & li mark {
            text-decoration: underline;
            background: none;
            font-weight: 600;
        }
    }
}
