﻿.form-group {
    &.buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & .btn {
            margin:0 0 0 $DEFAULT-MARGIN;
        }
    }

    &--centered {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &.form-group-half {
        display: flex;
        flex-direction: column;

        .property-row{
            display:flex;
            flex-direction:row;
        }

        div.form-group-half--item {
            flex-grow: 3;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &.action-links {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;

                a {
                    margin-left: 1rem;
                }
            }
        }

        &.form-group-half--inactive {
            .action-links {
                display: none;
            }
        }
    }

    & .form-control-label {
        margin-right: 10px;
        white-space: nowrap;
    }

    &.has-error input{
        border-color:$WARNING;
    }

    .validation-message {
        .help-block.error{
            margin-bottom:0;
        }
        color: $WARNING;
        font-size: 0.8rem;
        padding-top:0.2rem;
    }
}
