﻿@import './settings.scss';

.entity-information {
    display: flex;
    flex-direction: row;

    .btn {
        margin-left: 0.675rem;
    }

    .info-panel {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 16rem;
        font-size: 1rem;

        .info-panel-item {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1rem;

            .panel-item-label {
                font-weight: 700;
            }

            .panel-item-info {
                width: 90%;
            }

            .colour-blob {
                width: 10rem;
                height: 10rem;
                border-radius: 0.25rem;
                border: 1px solid black;
            }

            .colour-blob-review {
                width: 10rem;
                height: 2.5rem;
                border-radius: 0.25rem;
                border: 1px solid black;
            }
        }
    }

    .main-panel {
        display: flex;
        flex-direction: column;
        flex: 1;

        .main-panel-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 3rem;
            margin-bottom: 5rem;
            align-items: center;

            h2 {
                font-size: 1.2rem;
            }

            .button-row {
                display: flex;
            }
        }

        .main-panel-footer {
            margin-top: 2rem;
        }

        .main-panel-tabs {
            .tab-content {
                margin-top: 2rem;

                .tab-pane {
                    .form-group {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .button-row-bottom {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__unsaved {
        display: none;
        color: $WARNING;
        justify-content: flex-end;
        width: 100%;
        margin-top: $DEFAULT-MARGIN;

        &--active {
            display: inline-flex;
        }
    }
}

.startpage .entity-header,
.new-user .entity-header,
.edit-company .entity-header,
.company-list .entity-header,
.create-company .entity-header,
.connection-list .entity-header,
.edit-user .entity-header,
.user-list .entity-header,
.colourstandard-edit .entity-header,
.assignment-edit .entity-header,
.connection-details .entity-header,
.measurement-review .entity-header {
    @extend %entity-header;
}
