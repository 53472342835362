@import '../../../../../sass/settings.scss';

.switch-button {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;

  input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
      margin: 0;
      padding: 0;
      transform: none;
  }
    
  .switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 $DEFAULT-MARGIN;
    width: 50px;
    height: 30px;
    background: $GRAY;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }

  .switch-label .switch-label-content {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    border-radius: 45px;
    transition: 0.2s;
    background: $WHITE;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .switch-checkbox:checked + .switch-label .switch-label-content {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  .switch-label:active .switch-label-content {
    width: 45px;
  }

  .active {
    background: $CAPQ-LIME;
  }
}

