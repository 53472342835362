@import '../../../../../sass/settings.scss';

.manage-profiles {

    &__editable-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        background-color: $MEDIUM-GRAY;
        padding: $DEFAULT-PADDING;
    }
   
    .link-button {
        color: $BLUE-LIGHT;
        margin-bottom: $DEFAULT-MARGIN;
        margin-left: $DEFAULT-MARGIN;
    }

    .selected {
        outline: 1px dashed $BLACK;
    }

    .action-area {
        display:flex;
        justify-content: space-between;
    }

    .btn-danger {
        display: inline-block;
        margin-left: $DEFAULT-MARGIN
    }
}