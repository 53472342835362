.bar {
    clip-path: url(#clip);
  }
    
  .subBar { 
    fill: gray;
    opacity: 0.5;
  }
  
  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }
  
  .brush .extent {
    stroke: #fff;
    fill: steelblue;
    fill-opacity: .25;
    shape-rendering: crispEdges;
  }
  
    rect.mover {
              stroke: red;
              stroke-opacity: .1;
              fill: lightSteelBlue;
              fill-opacity: .5;
          }