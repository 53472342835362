.startpage {
    &__changelog {

        h2 {
            font-size: 1rem;
        }

        & .logs {
            padding-left: 10px;

            & .log {
                display:flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px;

                & .badge {
                    margin-right: $DEFAULT-MARGIN;
                    min-width: 80px;
                }

                & a {
                    color: #0072b9;
                }
        
                & time {
                    font-weight: 500;
                    margin-right: $DEFAULT-MARGIN;
                    white-space: nowrap;
                }


                & .fa-user {
                    color: $MEDIUM-GRAY;
                    margin-right: 4px;
                }
            }
        }
    }

    &__date-container {
        display: inline-flex;
        align-items: center;

        & label {
            margin-right: $DEFAULT-MARGIN;
        }
    }

    &__widget {
        padding: $DEFAULT-PADDING;
        display: inline-flex;
        flex-direction: column;
        border-radius: 10px;
        width: 100%;
        margin-right: $DEFAULT-MARGIN;
        margin-bottom: $DEFAULT-MARGIN;
        // box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.1);
    }

    &__login-options {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &:before {
            content:'';
            position: absolute;
            z-index:2;
            width: 100%;
            height:100%;
            background-color: white;
            opacity: 0.5;
            z-index: -1;
        }
    }

    &__form {
        width:800px;
        background-color: $WHITE;
        padding: $DEFAULT-PADDING;
        margin: $DEFAULT-MARGIN 0;
        border:1px solid $MEDIUM-GRAY;

        &:first-child {
            margin-top: 110px;
        }

        & select ,
        & input[type=text] {
            margin-bottom: $DEFAULT-MARGIN;
        }

        & .btn {
            display: inline-block;
        }

        &--admin {
            border: 1px solid $WARNING;
        }
    }
}