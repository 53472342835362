.capq-chart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow:hidden;
    /*flex-wrap: wrap;*/
    margin-bottom: $DEFAULT-MARGIN;

    & .triangle-text {
        font-size: 8px;
    }

    // & div {
    //     background-color: $DARK-GRAY;
    //     text-align: right;
    //     padding: 3px;
    //     margin: 1px;
    //     color: $CAPQ-LIME;
    // }

    &__legend-parent {
        display: flex;
        flex-direction: row;
        margin-top: 0.5rem;

        .legend-item {
            display: flex;
            flex-direction: row;
            margin-right: 2rem;
            align-items: center;

            .legend-item-blurb {
                width: 14px;
                height: 14px;
                border-radius: 10px 10px;
            }

            .legend-item-text {
                margin-left: 0.3rem;
                font-size: 0.7rem;
            }
        }
    }

    &__overlay {
        cursor: pointer;
        fill: none;
        pointer-events: all;
    }

    &__tooltip {
        fill: white;
        stroke: $GRAY;

        & .tooltip-value {
            font-weight: bold;
        }
    }

    &--400 {
        max-width: 400px;
    }
}

.review-cielab-chart {
    max-height: 300px;
}
