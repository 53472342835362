@import '../../../../../sass/settings.scss';

.edit-company{
    display: flex;
    width: 100%;

    &__content{
        width: 100%;
    }

    &__form {
           width: 100%; 
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
    }

    & .btn {
        margin-right: $DEFAULT-MARGIN;
    }
}