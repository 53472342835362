﻿.entitylisting {
    .top-section {
        /*box-shadow: 0 30px 16px -36px;*/
        padding: 0.7rem;
        /*margin-bottom: 2rem;*/
    }

    & .entity-header {
        @extend %entity-header;
    }

    &__action-panel {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        & .form-check input[type=checkbox] {
            transform: scale(1.5);
            margin-right: $DEFAULT-MARGIN;
        }

        & .form-group {
            margin-bottom: 0px !important;
        }

        & .action-bar1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &__search-type.btn {
                margin-left: 0;
            }

            & .clear-filter {
                flex-shrink: 0;
            }
        }

        & .action-bar2 {
            .filters-row {
                // justify-content: space-between;
                margin-top: 1rem;

                & > * {
                    margin-right: $DEFAULT-MARGIN;
                }

                .form-group {
                    label {
                        margin-bottom: 0;
                    }

                    .tag-search {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                    }

                    i {
                        margin-right: 1rem;
                    }

                    .tagify {
                        border: 1px solid #ced4da;

                        tag {
                            div {
                                padding: 0.1em 1.4em 0.1em 0.5em;
                            }
                        }

                        .tagify__input {
                            min-width: 250px;
                        }
                    }
                }
            }
        }

        & .btn {
            margin-left: 0.675rem;
        }

        & .dropdown-item {
            background-color: $WHITE;
        }

        & .filters-row {
            .dropdown-select {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            justify-content: flex-start;
        }
    }

    & .dropdown-select.form-group, & .form-group.form-check {
        margin-right: 1rem;
        padding-left: 0px;
    }
}
