@import '../../../../../../sass/settings.scss';

.connection-result-table{
    margin-top: $DEFAULT-MARGIN;

    .support-table{
        tr:nth-child(even) {background: #BFFFBF}
        tr:nth-child(odd) {background: #FFBFBF} 
    }

}