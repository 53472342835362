
@keyframes anim-capq-spinner {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes anim-capq-spinner {
    to {
        -webkit-transform: rotate(360deg);
    }
}

$check-height: 40px;
$check-width: 20px;
@keyframes anim-checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
}

.capq-app {
    width: 100%;
}

.capq-checkmark {
    position: relative;
    min-width: $check-height;
    min-height: $check-height;
    margin-right: $DEFAULT-MARGIN;
    
    &:after {
      content: '';
      left: 0;
      top: 0;
      position: absolute;
      animation-duration: 2s;
      animation-iteration-count: 2;
      animation-timing-function: ease;
      animation-name: anim-checkmark;
      transform: scaleX(-1) rotate(135deg) translate($check-width/2, -($check-width/2));
      opacity: 1;
      height: $check-height;
      width: $check-width;
      transform-origin: left top;
      border-right: 3px solid $CAPQ-LIME;
      border-top: 3px solid $CAPQ-LIME;
    }
}

.capq-spinner {
    position: relative;
    min-width: 80px;
    min-height: 80px;

    &:before {
        content:'';
        position: absolute;
        z-index:1100; //Needed this high value to go on top of dialogs :-(
        width: 100%;
        height:100%;
        background-color: white;
        opacity: 0.5;
    }

    &:after {
        content: 'Loading…';
        position: absolute;
        top: 40%;
        left: 50%;
        width: 80px;
        height: 80px;
        z-index: 1101; //Needed this high value to go on top of dialogs :-(
    }

    &:not(:required):after {
        content: '';
        border-radius: 50%;
        border: 2px solid rgba(0, 0, 0, .3);
        border-top-color: rgba(0, 0, 0, .6);
        animation: anim-capq-spinner .6s linear infinite;
        -webkit-animation: anim-capq-spinner .6s linear infinite;
    }
}

span[data-toggle=tooltip] { //Hack needed to get tooltip to disappear on mouse out on disabled buttons
    padding: 10px;
    margin: -10px;
}

