.assignment-edit {   
    &__selected-list {
        .iconHTML {
            flex-shrink: 0;
            margin-right: $DEFAULT-MARGIN;
        }

        .measurement {
            align-items: center;
        }
    }

    & input[type="checkbox"] {
        transform: scale(1.5);
        margin: $DEFAULT-MARGIN;
    }
    
    & .dataTable input[type="checkbox"] {
        margin: 0;
    }

    &__add-entity-dialog {

        .modal-header {
            margin-bottom: $DEFAULT-MARGIN;
        }

        .modal-content {
            padding: $DEFAULT-PADDING;
        }

        .capq-guide__measurements,
        .capq-guide__summary {
            overflow-y: scroll;
            max-height: 70vh;
        }
    }
    
    &__toolbar-top {
        display:flex;
        justify-content: flex-end;
        margin-bottom: $DEFAULT-MARGIN;
    }

    &__entity-add {
        &.step1 .assignment-edit__add-step2,
        &.step1 .assignment-edit__add-step3,
        &.step2 .assignment-edit__add-step1,
        &.step2 .assignment-edit__add-step3,
        &.step3 .assignment-edit__add-step1,
        &.step3 .assignment-edit__add-step2 {
            display: none;
        }

        &.step1 .assignment-edit__add-back-button {
            visibility: hidden;
        }
    }

    &__status-button {
        min-width: 140px;
        display: flex;
        justify-content: center;
    }

    &__toggle-status {
        input[type=checkbox] {
            margin: 0 $DEFAULT-MARGIN;
        }
        
        label {
            margin: 0 $DEFAULT-MARGIN 0 0;
        }
    }

    &__suppliers-add {
        &.step2 th:first-child {
            text-align: left !important;
        }
    }

    & .project-measurement {
        text-align: center;
    }

    & .creative-space-admin {
        display: flex;
        justify-content: center;
        border: 1px solid red;
        padding: calc(2 * #{$DEFAULT-PADDING}) 0;
        margin-top: calc(2 * #{$DEFAULT-MARGIN});

        & .btn {
            margin-right: $DEFAULT-MARGIN;
        }
    }
}

.assignment-create {   
    &__selected-list {
        .iconHTML {
            flex-shrink: 0;
            margin-right: $DEFAULT-MARGIN;
        }

        .measurement {
            align-items: center;

            .measurement__content-row .form-group.form-group--small {
                flex: 0.3;
            }
        }
    }

    & input[type="checkbox"] {
        transform: scale(1.5);
        margin: $DEFAULT-MARGIN;
    }
    
    & .dataTable input[type="checkbox"] {
        margin: 0;
    }


    & .project-measurement {
        text-align: center;
    }

    &__step4-head {
        margin-bottom: $DEFAULT-MARGIN;
        padding: $DEFAULT-PADDING 0;
        border-bottom: solid 1px lightgray;
        display: flex;
        flex-wrap: wrap;
    }
    &__step4-head-info {
        flex-basis: 350px;
        margin-bottom: $DEFAULT-MARGIN;
        margin-right: $DEFAULT-MARGIN;

        & span:first-child {
            font-weight: bold;
            margin-right: $DEFAULT-MARGIN;
        }
    }

    & .btn {
        margin-left: 0.675rem;
    }


    & .main-panel {
        display: flex;
        flex-direction: column;
        flex: 1;

        .main-panel-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 3rem;
            margin-bottom: 5rem;
            align-items: center;

            h2 {
                font-size: 1.2rem;
            }

            .button-row {
                display: flex;
            }
        }

        .main-panel-footer {
            margin-top: 2rem;
        }

        .main-panel-tabs {
            .tab-content {
                margin-top: 2rem;

                .tab-pane {
                    .form-group {
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        .button-row-bottom {
            display: flex;
            justify-content: flex-end;
        }
    }

    & .headings {
        font-weight: bold;
        margin-right: 3px;
    }
      
    & .labeled {
        font-weight: bold;
        text-align: right;
    }
    
    & .capq-guide__summary {
        &--full-height {
            overflow: visible;
        }

        .form-group{
            margin-bottom: 1rem;
            margin-right: 1rem;
        }
    }
      
    & .capq-guide__bulk-content-details {
        display: flex;
        max-width: 745px;
        margin-top: 10px;
    }

    & .capq-guide__bulk-container {
        margin-bottom: 10px;
        margin-left: 0;
        background-color: #f6f6f6;
        border: 1px solid #e6e6e6;
    }

    & .date-control{
        width: 50%;
    }

    & .new-project_modal{
        max-width: 500px;
    }

    & .detail-row{
        p {
            margin-bottom: 0;
        }
        
        .form-group{
            margin-bottom: 0rem;
        }
    }
}


.startpage .entity-header,
.new-user .entity-header,
.colourstandard-edit .entity-header,
.assignment-edit .entity-header,
.measurement-review .entity-header {
    @extend %entity-header;
}
