@import '../../../../sass/settings.scss';

.login-page {
    padding: calc(2 * #{$DEFAULT-PADDING}) 0 0 calc(2 * #{$DEFAULT-PADDING});

    &__top {
        display: flex;
        justify-content: space-between;
    }

    &__top-link {
        margin-right: $DEFAULT-MARGIN;
    }

    & .ncs-logotype {
        margin-bottom: $DEFAULT-MARGIN;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $BLACK;
        width: fit-content;
        padding: $DEFAULT-PADDING;
    }

    &__form-container {
        margin-top: calc(4 * #{$DEFAULT-MARGIN});
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}