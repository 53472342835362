﻿.tagify {
    tag {
        div {
            padding: 0.25em 1.5em 0.25em 0.5em;
        }
    }

    tag:hover:not([readonly]) div::before {
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        box-shadow: 0 0 0 16px #D3E2E2 inset;
    }

    &__dropdown { /* Ugly override due to Tagify's way of positioning dropdown absolute on page :-( */
        z-index: 9999 !important;
    }
}
