.capq-guide {
    & .btn i.fa-arrow-circle-right {
        margin: 0 0 0 0.5rem;
    }

    &__instrumentlist {
        display: none;

        &--visible {
            display: block;
        }
    }

    &__bulk-container {
        margin-bottom: $DEFAULT-MARGIN;
        margin-left: $DEFAULT-MARGIN;
        background-color: $LIGHT-GRAY;
        border: 1px solid #e6e6e6;
    }

    &__bulk-heading {
        display: flex;
        justify-content: space-between;
        padding: $DEFAULT-PADDING;
        margin: 0 !important;
        cursor: pointer;

        & i {
            -webkit-transition: -webkit-transform .5s ease-in-out;
            -ms-transition: -ms-transform .5s ease-in-out;
            transition: transform .5s ease-in-out;
        }

        &[aria-expanded="true"] {
            border-bottom: solid 1px #ced4da;

            & i {
                transform: rotate(180deg);
            }
        }
    }

    &__bulk-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $DEFAULT-PADDING;

        & .btn {
            margin: 0 !important;
        }

        section {
            display: flex;

            .form-group {
                margin-right: $DEFAULT-MARGIN;
            }
        }
    }

    &__bulk-content-gloss {
        display: flex;
        max-width: 300px;
        margin-top: $DEFAULT-MARGIN;

        & .form-group {
            margin-right: $DEFAULT-MARGIN;
        }
    }

    &__section {
        display: none;

        .form-control-file, .btn {
            width: auto;
            margin: $DEFAULT-MARGIN;
        }

        &--visible {
            display: flex;
            flex-direction: column;
            margin-bottom: $DEFAULT-MARGIN;
        }

        &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            border-bottom: solid 1px #f2f2f2;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;

            & h3 {
                flex: 1;
                margin: 12px 12px 12px 0;
            }
        }

        .button-toggle-more {
            align-self: flex-end;
            justify-content: center;
            min-width: 122px;
        }

        .upload-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: $DEFAULT-MARGIN;

            // &.capq-spinner:after {
            //     width: 80px;
            //     height: 80px;
            //     top: 25%;
            //     left: auto;
            // }

            &.has-advanced-upload {
                background-color: $LIGHT-GRAY;
                outline: 2px dashed $GRAY;
                padding: 20px;
            }

            &.is-dragover {
                background-color: grey;
            }

            .upload__input {
                width: 100%;

                &--uploading {
                    opacity: 0.2;
                }
            }

            .upload__input, .upload__tobe-uploaded, .upload__label {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .fa-cloud-upload-alt {
                color: $GRAY;
                font-size: 44px;
                margin: 20px 0;
            }

            .form-control-file {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;

                & + .upload__label {
                    cursor: pointer;
                    width: 100%;

                    .btn-primary {
                        margin-bottom: $DEFAULT-MARGIN;
                    }
                }

                &:focus + .upload__label {
                    outline: 1px dotted #000;
                    outline: -webkit-focus-ring-color auto 5px;
                }

                & + .upload__label * {
                    pointer-events: none;
                }
            }
        }

        .upload__tobe-uploaded {
            align-items: flex-start;

            .fa-check {
                margin-right: $DEFAULT-MARGIN;
                color: $GREEN;
            }
        }

        & .id-number {
            border: 1px solid $GRAY;
            color: $WHITE;
            background-color: $GRAY;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0.25rem;
            margin: $DEFAULT-MARGIN;
        }

        & .import-error {
            & p {
                background-color: $ERROR;
                margin-bottom: 20px;
                padding: 4px;
                text-align: center;
            }
        }
    }

    &__files, &__summary {
        overflow-y: scroll;
        overflow-x: hidden;
        flex-basis: 68vh;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__files--inprogress {
        opacity: 0.2;
    }

    &__file {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: $DEFAULT-PADDING;
        margin-bottom: calc(#{$DEFAULT-MARGIN} * 2);
        margin-right: $DEFAULT-MARGIN;
    }

    &__measurements {
        display: flex;
        flex-direction: column;

        & .disabled {
            position: relative;

            &:before {
                content: '';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #fff;
                position: absolute;
                opacity: 0.8;
                z-index: 1;
            }
        }

        & .measurement {
            display: flex;
            position: relative;
            justify-content: space-between;
            background-color: #f7f7f7;
            box-shadow: 4px 4px 5px #e6e6e6;
            margin-bottom: 22px;
            padding: $DEFAULT-PADDING;
            max-height: 500px;
            opacity: 1;

            &.clickable {
                cursor: pointer;

                label {
                    cursor: pointer;
                }
            }

            &.selected {
                background-color: #e3e3e3;
            }

            &.selected:hover {
                background-color: #d8d8d8;
            }

            &.hidden {
                max-height: 0;
                opacity: 0;
                margin: 0;
                padding: 0;
                margin-top: -6px;
                overflow: hidden;
            }

            & .import {
                align-self: flex-start;
                margin-top: 32px;
            }

            &:hover {
                background-color: #e9e9e9;
            }

            &__checkbox {
                margin: 22px $DEFAULT-MARGIN 0 0;
            }
            &__checkbox_create {
                margin: 0px $DEFAULT-MARGIN 0 0;
            }

            &__content {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            &__content-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                & .form-group {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    margin-right: $DEFAULT-MARGIN;

                    &.standard-name {
                        flex: 2;
                    }

                    &.target-standard {
                        flex: 2;
                    }

                    &.measurement-gloss{
                        flex:0.8;
                    }

                    &.mandatory-mark label:after {
                        content: '*';
                        color: $CAPQ-RED;
                        margin-left: 4px;
                    }

                    &.hidden {
                        display: none;
                    }
                }

                & section {
                    position: relative;
                    display: flex;
                    flex: 1;
                }

                & .form-group.mandatory:after {
                    content: '* Required';
                    font-size: 10px;
                    color: $CAPQ-RED;
                }

                &--bottom {
                    margin-top: $DEFAULT-MARGIN;
                    max-width: 560px;
                    max-height: 100px;
                    opacity: 1;
                    transition: max-height 0.5s ease-in-out, opacity 1s;
                    -webkit-transition: max-height 0.5s ease-in-out, opacity 1s;

                    &.measurement__content-row--hidden {
                        transition: max-height 0.5s ease-in-out, opacity 0.2s;
                        -webkit-transition: max-height 0.5s ease-in-out, opacity 0.2s;
                    }

                    & .material-select {
                        flex: 2.5;
                    }
                }

                &--hidden {
                    max-height: 0;
                    opacity: 0;
                }
            }
        }
    }

    &__error-messages {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: '';
            z-index: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            background-color: $WHITE;
            opacity: 0.6;
            z-index: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__error-message {
        display: inline-block;
        padding: 7px;
    }

    & input[type="checkbox"] {
        transform: scale(1.5);
        margin: 0 $DEFAULT-MARGIN;
    }

    .form-group {
        margin-bottom: 0;
    }

    &__upload-area {
        display: flex;
        flex-wrap: wrap;
        padding: $DEFAULT-PADDING;
    }

    &__upload-item {
        font-family: 'ToucheLight';
        font-size: 13px;
        display: inline-flex;
        justify-content: space-between;
        margin-right: 6px;
        margin-bottom: 7px;
        border-radius: 1.24rem;
    }

    &__toolbar {
        display: none;

        &--visible {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .capq-guide__section--upload & {
                justify-content: flex-end;
            }
        }

        & .progress {
            display: none;

            &--active {
                display: flex;
                min-height: 22px;

                & .progress-bar {
                    padding: 0 $DEFAULT-PADDING;
                    font-weight: bold;
                }
            }

            &.import-error-progressbar {
                background-color: $ERROR;
            }
        }

        & .btn-import-done i {
            margin-right: 0;
            margin-left: 0.5rem;
        }
    }

    &__toolbar-button-group {
        display: flex;
        align-items: center;
    }

    &__summary-measurements {
        caption {
            caption-side: top;
        }

        tr:not(.import-error):nth-child(even) {
            background-color: $LIGHT-GRAY;
        }

        th {
            border-bottom: 1px solid $LIGHT-GRAY;
            padding-bottom: 0.5em;
            padding-right: 0.5em;

            &:first-child {
                text-align: center;
            }
        }

        td {
            padding: calc($DEFAULT-PADDING / 2) 0;

            & .id-number {
                width: 40px;
                height: 40px;
                border-radius: 20px;
            }
        }
    }
}

.colourstandard-download {
    &__message {
        display: inline-block;
        color: $INFO;

        &--star:not(:empty):before {
            content: '* ';
            color: $WARNING;
        }
    }
    
    & input[disabled] + label .radio-text .radio-header,
    & input[disabled] + .radio-text .radio-header {
        color: $WARNING;
        &:before {
            content: '* ';
            color: $WARNING;
        }
    }

    & input[disabled] + label .radio-text .radio-header,
    & input[disabled] + .radio-text .radio-header {
        color:$GRAY;
        text-decoration: line-through;
    }

    &__support-panel {
        border: 1px solid $WARNING;
        padding: $DEFAULT-PADDING;

        & .links {
            display: flex;
            justify-content: flex-start;
             & > * {
                 margin-right: $DEFAULT-MARGIN;
             }
        }
    }
}