@import '../../../../../sass/settings.scss';

.guide-container{

    .button-row-bottom{
        padding-top: $DEFAULT-PADDING;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-group{
            display:flex
        }

        .right-group{
            display: flex;
            align-items: center;
        }
    }


    &__entity-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: solid 1px $LIGHT-GRAY;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
    }

    .btn {
        margin-left: $DEFAULT-MARGIN;
    }
}