﻿@import './settings.scss';

.modal-content {

    // General
    p, ul {
        margin-bottom: 1rem;
    }

    .modal-header {
        .modal-title {
        }

        button {
        }
    }

    .modal-body {
        .form-group:first-child {
            margin-top: 1rem;
        }

        padding: 1.6rem;
        padding-top: 0rem;
        padding-bottom: 0.5rem;

        ul {
            margin-left: 1rem;

            li {
            }
        }

        input[type="radio"] {
            /*margin-bottom: 0.7rem;*/
            margin-top: 0.12rem;
            float: left;
        }

        /*label > .radio-text {
            margin-left: 0.9rem;
            display: block;
        }*/

        .radio-text {
            margin-left: 1.8rem;
            display: block;
        }

        .radio-header {
            display: block;
            font-weight: 700;
        }

        .radio-body {
        }

        .modal-checkboxes {
            .form-check {
                margin-bottom: 0.5rem;
            }
        }

        .modal-select {
           width: 20rem;
        }


        .options-header {
            margin-top: 1.5rem;
            margin-bottom: 0.5rem;
        }

        .radio.radio-option {
            margin-bottom: 0.5rem;

            &:hover {
                cursor: pointer !important;
            }
        }
    }

    .modal-footer {
        button {
        }

        .btn {
        }
    }
}

.modal-block {
    display: block;
    padding-right: $DEFAULT_MARGIN;
}

.modal-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5
}

.body-wait {
    &:before{
        content: "Please wait...";
        position: fixed;
        top: 0;
        padding-top: 60px;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: white;
        /* width: 100%; */
        z-index: 9000;
        /* height: 100%; */
        opacity: 0.9;
        font-weight: bold;
        font-size: 2rem;
        text-align: center;
    }
}