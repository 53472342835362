@import '../../../sass/settings.scss';

body.body-full-page-width {
    background-color: $CAPQ-LIGHT-LIME;
}

.capq-app-child {
    width: 100%;

    .message-toast {
        font-size: 1rem;
        position: fixed;
        z-index: 3;
        background-color: $WHITE;
        margin: $DEFAULT-MARGIN;
        padding: $DEFAULT-PADDING;
        border-radius: 3px 3px 3px 3px;
        box-shadow: 0 0 10px $LIGHT-GRAY;
        color: $BLACK;
    
        transition: transform .6s ease-in-out;
        animation: toast-in-right .7s;
        
    
        & i {
            margin-right: $DEFAULT-MARGIN;
        }
    
        &--top-right {
            top: calc(2*$DEFAULT-MARGIN);
            right: $DEFAULT-MARGIN;
        }
            
        &--bottom-right {
            bottom: calc(2*$DEFAULT-MARGIN);
            right: $DEFAULT-MARGIN;
        }
        
        &--top-left {
            top: calc(2*$DEFAULT-MARGIN);
            left: $DEFAULT-MARGIN;
        }
        
        &--bottom-left {
            bottom: $DEFAULT-MARGIN;
            left: $DEFAULT-MARGIN;
        }
    
        &--success {
            background-color: $MESSAGE-SUCCESS-BACKGROUND-COLOR;
            color: $MESSAGE-SUCCESS-TEXT-COLOR;
        }
    
        &--error {
            background-color: $MESSAGE-ERROR-BACKGROUND-COLOR;
            color: $MESSAGE-ERROR-TEXT-COLOR;
        }
    
        &--warning {
            background-color: $MESSAGE-WARNING-BACKGROUND-COLOR;
            color: $MESSAGE-WARNING-TEXT-COLOR;
        }
    
        &--info {
            background-color: $MESSAGE-INFO-BACKGROUND-COLOR;
            color: $MESSAGE-INFO-TEXT-COLOR;
        }
    
        &__header {
            color: $WHITE;
        }   
        
        &__message {
            margin: 0;
            text-align: left;
            height: 25px;
            margin-left: -1px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $WHITE;
        }   
    }
}
