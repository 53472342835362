.capq_debuginformation {
    position: absolute;
    top: 70px;
    left:0;
    display: block;
    padding: 2px 0px;
    text-align: center;
    width: 100%;
    font-size: 0.8em;

    &.Debug {
        background-color: palegreen;
    }

    &.Demo {
        background-color: lightblue;
    }

    &.Dev, &.Sandbox {
        background-color: orange;
    }

    &.NcsCoreTest {
        background-color: hotpink;
    }

    &.StagingSlot {
        background-color: blueviolet;
        color:white;
    }

    & .debug-label {
        font-weight:700;
    }

    & .debug-value {
        margin-right:1rem;
    }
}
