$font-family-base: 'ToucheLight';
$font-weight-base: inherit;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 1.75 !default;
$h2-font-size: $font-size-base * 1.3 !default;
$h3-font-size: $font-size-base * 1.2 !default;
$h4-font-size: $font-size-base * 1.15 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;
$link-color: #000000;
$link-decoration: 'none';
$body-color: #000000;
$nav-link-disabled-color: #6c757d70;
