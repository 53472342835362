@import '../../../../../sass/settings.scss';

.company-list {
    display: flex;

    &__content{
        width: 100%;
    }

    .button-row {
        display: flex;
        justify-content: flex-end;
    }

    .cell-centered {
        display: flex;
        justify-content: center;
    }
}
