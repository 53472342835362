.capq_header {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .language-dropdown {
        cursor: pointer;
        margin: 0 0 0 $DEFAULT-MARGIN;
        position: relative;

        & .fa-globe {
            font-size: 1.5rem;
        }

        & ul {
            display:none;
            list-style: none;
            padding: $DEFAULT-PADDING;
            background-color: $WHITE;
            color: $BLACK;
            border-radius: 6px;
            position: absolute;
            top: 30px;
            right: -15px;
            z-index: 2;
            box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);

            & li {
                padding-left: $DEFAULT-PADDING;
                padding-right: $DEFAULT-PADDING;
            }

            &:before {
                content: '';
                background-color: $WHITE;
                width: 10px;
                height: 10px;
                display: block;
                transform: rotate(45deg);
                position: absolute;
                top: -4px;
                right: 22px;
            }
        }

        &:hover ul {
            display: block;
        }

        & li:hover {
            background-color: #f8f9fa;
        }
    }
}

.grv-widget-tag-bottom-right {
    bottom: 6px !important;
}