﻿@import './settings.scss';

.measurement-review {
    padding: $DEFAULT-PADDING;
     
    &__selected-row {
        background-color: lemonchiffon;
    }

    &__spectral {
        display: flex;
        flex-direction: column;

        & .form-group {
            align-self: flex-end;
            color: $CAPQ-RED;
            & input[type="checkbox"] {
                transform: scale(1.5);
                margin: $DEFAULT-MARGIN;
            }
        }
    }

    &__out-of-tolerance {
        color: red;
    }

    &__in-tolerance {
        color: green;
    }

    &__table {
        font-size: 16px
    }

    &__size {
        max-width: 1000px !important;
        min-width: 570px;
        padding-bottom: 30px;
    }

    &__comment-container {
        border: 1px solid #dee2e6;
        margin-bottom: 10px;
        border-radius: .5rem;
        font-size: 13px;
    }

    &__comment-textarea {
        font-size: 13px;
        margin-bottom: 10px;
    }

    &__comment-button-container {
        justify-content: flex-end;
    }

    &__comment-content {
        padding: 10px;
    }

    .svg-review {
        transition: transform 1s;
    }

    #snackbar {
        visibility: hidden;
        min-width: 250px;
        margin-left: -125px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 15px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 30px;
        font-size: 17px;
    }

    #snackbar.show {
        visibility: visible;
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }

        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }

        to {
            bottom: 0;
            opacity: 0;
        }
    }
}
