@import '../../../../../sass/settings.scss';

.edit-user {
    button {
        margin-left: $DEFAULT-MARGIN;
    }

    .modal-dialog {
        width: 25rem;
    }
}