/*EXTERNAL*/
@import "./bootstrapoverrides-by-variables.scss";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~jquery-datatables-checkboxes/css/dataTables.checkboxes.css";

/*CAPQ SPECIFIC*/
@import "./settings.scss";
@import "./common.scss";
@import "./bootstrapoverrides.scss";
@import "./datatablescapq.scss";
@import "./entityinformation.scss";
@import "./entitylisting.scss";
@import "./modals.scss";
@import "./errorpage.scss";
@import "./tagify.scss";

/* EXTENDS */
@import "./extends/headers.scss";

/*Features*/
@import "./debug.scss";
@import "./header.scss";
@import "./startpage.scss";
@import "./assignments.scss";
@import "./tolerances.scss";
@import "./colourstandards.scss";
@import "./searchfieldsuggest.scss";
@import "./forms.scss";
@import "./review.scss";
@import "./new-user.scss";
@import "./user-list.scss";

/*Charting */
@import "./chart.scss";
