@import '../../../../../sass/settings.scss';
@import '../../../../../sass/mixins.scss';

.login-form {
    opacity: 0;
    @include prefix(transition, opacity .5s ease-in-out, webkit moz ms);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $WHITE;
    background-color: $DARK-GRAY;
    padding: calc(8 * #{$DEFAULT-PADDING});

    h2 {
        color: $WHITE;
    }
    
    &--visible {
        opacity: 1;
    }

    & input,
    & input:focus {
        color: $WHITE;
        border: none;
        border-bottom: 1px solid $WHITE;
        border-radius: initial;
        background-color: $DARK-GRAY;
    }

    & .form-group {
        width: 400px;
    }

    & .success-message {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .form-group.buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 0;

        & a {
            color: $WHITE;
            margin-right: $DEFAULT-MARGIN;
        }
    }

    & .capq-logotype {
        display: flex;
        justify-content: center;
        margin: $DEFAULT-MARGIN 0 calc(4 * #{$DEFAULT-MARGIN}) 0;
    }
    
    & .btn-primary,
    & .btn-primary:hover,
    & .btn-primary:focus,
    & .btn-primary:active
    {
        color: $BLACK !important;
        background-color: $CAPQ-LIGHT-LIME !important;
        font-weight: bold !important;
        border: none !important;
        border-radius: 0;
    }
    & .btn-primary:hover {
        opacity: 0.5;
    }

    & .btn-secondary {
        background-color: transparent !important;
    }

    & .error {
        padding: $DEFAULT-PADDING 0;
        color: $CAPQ-LIME;
    }
}
