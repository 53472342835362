﻿.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.searchmatch {
    background-color: #4885c5;
    color: white;
}

.autocomplete-items>div {
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow:hidden;
    max-height:100px;

    &.deleted{
        max-height:0;
        transition:max-height 0.25s ease-in-out;
        border:0;
    }

    .trash-can-container {
        width:40px;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        &:hover{
            font-size:1.2rem;
        }
    }

    .trash-can {
        color: red;
    }

    .text-section {
        padding: 10px;
        display: block;
        flex-grow: 30;
    }

    &.autocomplete-active {
        .text-section {
            /*when navigating through the items using the arrow keys:*/
            background-color: #4885c5 !important;
            color: #ffffff;
        }
    }

    .text-section:hover {
        /*when hovering an item:*/
        background-color: #4885c5;
        color: white;
    }
}




