﻿.error-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .icon {
        margin-right: 1rem;
    }

    .heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
    }

    .body {
    }

    .error {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    .property {
    }

    .stack-trace {
        margin-top:1rem;
    }

    .error-label {
        font-weight: 700;
        margin-right: 0.78rem;
    }
}
